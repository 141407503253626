/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');*/

*{
  padding: 0;
  margin: 0; 
  box-sizing: border-box;
  font-family:'Roboto','Open Sans', "Inter", sans-serif;
  font-family:"Inter", sans-serif;
  scroll-behavior: smooth;
  overflow-y:none;
}



a{
  text-decoration: none;
  color: #000;
} 
:root{
  --gray-color:#f1faee;
  --gray-color:#F5F5F5;
  --main-color:#1757DB;
  --dark-color:#2E6D8C;
  --border-color:rgba(207, 205, 205, 0.356);
  --bg-color:#F5F5F5;
  --box-color:#fff;
  --top-color:#fff;
  --main-dark-color:#161A1D;
  --white-color:#fff;
  --black-color:#000;
  --main-white-color:#fff;
}
:root .dark-theme{
  --bg-color:#202124;
  --box-color:#303134;
  --border-color:rgba(207, 205, 205, 0.116);
  --top-color:#161A1D;
  --main-dark-color:#202124;
  --white-color:#dadce0;
  --main-white-color:#e3e3e3;
  --black-color:#dadce0;
  --dark-color:#0072B4;
  --gray-color:#f1faee50;
}
button{
cursor: pointer;
}

input,button,textarea{
  outline: none;
  border: 1px solid #5c5c5c44;
}



/*

cards:box-shadow:0 6px 30px rgba(182, 186, 203, 0.3);
body:background: linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%);;

*/


body{

background: #F3F3F3;
position: relative;

}


/*****Icon colors******/
.dashboard .red-icon{
background: #F8DED5;
}
.dashboard .orange-icon{
background:#F5ECD3;
}
.dashboard .blue-icon{
background:#D5E1F8;
}
.dashboard .green-icon{
background:#DAF9EA;
}


/******* dashboard *********/
.dashboard{
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
}

.dashboard .main-dashboard{
width: 100%;
display: flex;
flex-direction: column;
flex: 1;
padding: 1rem 1.7rem;
overflow: scroll;

}

.btn_open_close_menu{
position: absolute;
top: 2rem;
left: 1.5rem;

/*transform: translate(-2rem,-50%);*/

/*box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);*/
width: 30px;
height: 30px;
border-radius:50%;
border:1px solid var(--border-color);
align-items: center;
justify-content: center;
z-index: 5;
background: #fff;
cursor: pointer;
display: none;
}
.btn-close-menu{
position: absolute;
top: 50%;
box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
width: 35px;
height: 35px;
border-radius:50%;
border:1px solid var(--border-color);
align-items: center;
justify-content: center;
z-index: 1;
background: #fff;
cursor: pointer;
top: 1rem;
right: 1rem;
display: none;
}
.top .btn_open_close_menu svg{
opacity: .5;
}

.top .btn_open_close_menu:hover svg{
 opacity: 1;
}


.dashboard .main-dashboard .top{
   position: relative;
   width: 100%;
   display: flex;
   background: #fff;
   padding: 0.6rem 1rem;
   border-radius: 0.3rem;
   align-items: center;
}
.dashboard .main-dashboard .top .burger{
  display: none;
}
.dashboard .main-dashboard .top .current_tab {
   display: flex;
   flex-direction: column;
   margin-left: 1rem;

}
.dashboard .main-dashboard .top .current_tab .tab{
   text-transform: capitalize;
}
 .dashboard .main-dashboard .top .current_tab .path{
   font-size: .8rem;
   display: flex;
   align-items: center;
   opacity: .6;
 }
 .dashboard .main-dashboard .top .current_tab .path.active{
   margin-top: 0.5rem;
 }
 .dashboard .main-dashboard .top .current_tab .path label{
    color: #1757DB;
    margin-right: .3rem;
    cursor: pointer;
 }
 .dashboard .main-dashboard .top .current_tab .path label:hover{
  opacity: .7;
 }

.dashboard .main-dashboard .top .search_container{
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
}

.dashboard .main-dashboard .top .search_container .search-div
{
    width: 60%;
    height: 40px;
    position: relative;

}

.dashboard .main-dashboard .center_search,
  .dashboard .main-dashboard .center_search .left_side
 {
  display: flex;
  align-items: center;
}
.dashboard .main-dashboard .center_search .filter{
   display: flex;
   margin-left: 1.5rem;
   position: relative;
}

.dashboard .main-dashboard .center_search .filter .btn-filter{
  display: flex;
  height: 40px;
  align-items: center;
  padding: 0 0.8rem;
  border: 1px solid #ebebeb;
  border-radius: 3rem;
  background: #fff;
  cursor: pointer;
}
/*.dashboard .main-dashboard .center_search .filter.show .btn-filter,*/
.dashboard .main-dashboard .center_search .filter.on .btn-filter
{
  color: #fff;
  background: #1757DB;
}
  .dashboard .main-dashboard .center_search .filter.show .btn-filter{
    opacity: .8;
  }
/*.dashboard .main-dashboard .center_search .filter.show .btn-filter svg,*/
.dashboard .main-dashboard .center_search .filter.on .btn-filter svg
{
  fill: #fff !important;
}
.dashboard .main-dashboard .center_search .filter .btn-filter:active{
  opacity: .8;
}
.dashboard .main-dashboard .center_search .filter .btn-filter svg{
  margin-right: 0.5rem;
}

.dashboard .main-dashboard .center_search .filter .filters{
    position: absolute;
    top: 43px;
    left: 0;
    background: #fff;
    border-radius: 0.3rem;
    padding: 0.3rem;
    border: 1px solid #ebebeb;
    width: 200px;
    box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
    z-index: 1;
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
    opacity:0;
    transform: translateY(-0.2rem);
    transition: 0.1;
    pointer-events: none;
    transition: 0.2s;

}
 .dashboard .main-dashboard .center_search .filter.show .filters{
   opacity: 1;
   transform: translateY(0);
   pointer-events: visible;
 }

 .dashboard .main-dashboard .center_search .filter .filters .top{
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.6rem;
 }

   .dashboard .main-dashboard .center_search .filter .filters .top .clean{
    font-size: 0.9rem;
    opacity: .8;
    cursor: pointer;
   }

   .dashboard .main-dashboard .center_search .filter .filters .top .title{
      color: var(--main-color);
   }

   .dashboard .main-dashboard .center_search .filter .filters .top .clean:hover{
    cursor: pointer;
    text-decoration: underline;
   }

   .dashboard .main-dashboard .center_search .filter .filters .column{
      margin-bottom: 1rem;
   }
   .dashboard .main-dashboard .center_search .filter .filters .column .options div{
     display: flex;
     margin-bottom: 0.2rem;
     padding-left: 0.3rem;
   }
   .dashboard .main-dashboard .center_search .filter .filters .column .options input{
      margin-right: 0.4rem;
      cursor: pointer;
   }
   .dashboard .main-dashboard .center_search .filter .filters .column .options label{
    opacity: .8;
    cursor: pointer;
    display: flex;
    align-items: center;
   }

   .dashboard .main-dashboard .center_search .filter .filters .column  .name{
      opacity: .7;
      font-size: 0.85rem;
      margin-bottom: 0.2rem;
   }

.dashboard .main-dashboard .top .search_container .search-div input,
.dashboard .main-dashboard .center_search  input[type=text]{
  border-radius: 3rem;
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #ebebeb;
  padding-right: 3rem;

}

.dashboard .main-dashboard .top .search_container .search-div .search-btn,
.dashboard .main-dashboard .center_search .search-btn
{
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: .5;
}



.dashboard .main-dashboard .top .right-options,
.dashboard .main-dashboard .top .right-options .user .user_data
{
  display: flex;
  align-items: center;

}
 .dashboard .main-dashboard .top .right-options .user{
  position: relative;
 }
.dashboard .main-dashboard .top .right-options .user .info{
  display: flex;
  flex-direction: column;
  text-align: right;
  cursor: pointer;
}
  .dashboard .main-dashboard .top .right-options .user .user_data{
    cursor: pointer;
  }
  .dashboard .main-dashboard .top .right-options .user .user_data:hover .info,
  .dashboard .main-dashboard .top .right-options .user .user_data:hover .avatar
  {
    opacity: .8;

  }


.dashboard .main-dashboard .top .right-options .user .info .name{
  font-size: 0.9rem;
}

.dashboard .main-dashboard .top .right-options .user .avatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ebebeb;
  margin-left: 0.8rem;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

  .dashboard .main-dashboard .top .right-options .user .avatar svg{
    opacity: .3;
  }

 .dashboard .main-dashboard .top .right-options .top-tabs > span{
   cursor: pointer;
 }

.dashboard .main-dashboard .top .right-options .top-tabs >  span:hover{
  opacity: .5;
}

.dashboard .main-dashboard .top .right-options .notifications-dialog{
  background: #fff;
  position: absolute;
  padding: 0.3rem 0;
  border-radius:0.3rem;
  height: 400px;
  width: 320px;
  top: calc(100% + 1rem);
  right: 0.5rem;
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  z-index: 1;
  border:1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}
.dashboard .main-dashboard .top .right-options .notifications-dialog .messages{
  flex: 1;

}
 .dashboard .main-dashboard .top .right-options .notifications-dialog .messages .no-messages{
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: .7;
   font-size:  0.9rem;
 }
 .dashboard .main-dashboard .top .right-options .notifications-dialog .messages .message{
    border-bottom:  1px solid var(--border-color);
    padding: 0.5rem 0.6rem;
 }

 .dashboard .main-dashboard .top .right-options .notifications-dialog .messages .message .type{
    background: var(--main-color);
    border-radius: 3rem;
    color: #fff;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
 }

.dashboard .main-dashboard .top .right-options .notifications-dialog .messages .message .__top{
     margin-bottom: 0.3rem;
     display: flex;
     justify-content: space-between;
     align-items: center;
     opacity: .7;
}

.dashboard .main-dashboard .top .right-options .notifications-dialog .messages .message .date{
    font-size: 0.9rem;
}




 .dashboard .main-dashboard .top .right-options .notifications-dialog .messages .m{
     opacity: 1;
     font-size:  0.95rem;
 }


.dashboard .main-dashboard .top .right-options .notifications-dialog:after{
  position: absolute;
  width: 16px;
  height: 16px;
  transform: rotate(-45deg) translateX(70%);
  border-top:1px solid var(--border-color);
  border-right:1px solid var(--border-color);
  content: "";
  top: 0;
  right: 1.2rem;
  background: #fff;
}


.dashboard .main-dashboard .top .right-options .notifications-dialog .top-title{
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--border-color);  
    padding: 0.3rem 0.5rem;
    align-items: center;
    justify-content: space-between;
}
  .dashboard .main-dashboard .top .right-options .notifications-dialog .top-title .mark-as-read{
      font-size: 0.8rem;
      cursor: pointer;
}
.dashboard .main-dashboard .top .right-options .notifications-dialog .top-title .mark-as-read:hover{
  opacity: .6;
}

.dashboard .main-dashboard .top .right-options .user_options{
  background: #fff;
  position: absolute;
  padding: 0.3rem 0;
  border-radius:0.3rem;
  width: 150px;
  top: calc(100% + 1rem);
  right: 0;
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  z-index: 1;

}



.dashboard .main-dashboard .top .right-options .user_options span{
   padding: 0.5rem 0;
   cursor: pointer;
   display: flex;
   width: 100%;
   justify-content: center;
}
.dashboard .main-dashboard .top .right-options .user_options span:hover{
  background: #f8f9fa;
}


.dashboard .main-dashboard .top .right-options .top-tabs{
  margin-left: 2rem;
}

/**** Menu ******/
.dashboard .left-menu h1{
font-size: 1.3rem;
}

.dashboard .left-menu{
  width: 220px;
  height: 100%;
  background:#fff;
  position: relative;
  transition: 0.2s ease-in;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 99;
}


.dashboard .left-menu ._c{
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  transition: 0.2s ease;
  padding: 1rem 1.5rem;
}


.dashboard  .left-menu ._c .center{
  padding-bottom: 40px;
}


/*.dashboard  .left-menu{
 width: 42px;
 transition: 0.2s ease-out;
}

.dashboard .left-menu ._c{
  transform: translateX(-21px);
}*/


/*.container .content .left-menu{
  width: 220px;
  height: 100%;
  background:var(--main-dark-color);
  color: #fff;
  position: relative;
  transition: 0.2s ease-in;
  
}
.container.hide .content .left-menu{
 width: 42px;
 transition: 0.2s ease-out;
}

.container .content .left-menu ._c{
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  transition: 0.2s ease;
 
}
.container.hide .content .left-menu ._c{
  transform: translateX(-21px);
 
}*/

.dashboard .left-menu .top{
width: 100%;
padding: 1rem 0;
text-align: center;
font-weight: 600;
font-size: 1.2rem;
margin-bottom: 1.7rem;
}


.dashboard .left-menu .center ._nav-link{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 0.5rem;
font-weight: 400;
font-size: 14px;
}

.dashboard .left-menu .center  ._nav-link  .main-link{
padding: 0.3rem;
border-radius: 0.4rem;
cursor: pointer;
width: 100%;
}


.dashboard .left-menu .center ._nav-link .dropdown-options{
width: 100%;
flex-direction: column;
margin-left: 5rem;
padding-top:  0.4rem;
font-size: 0.8rem;
display: none;
}

.dashboard .left-menu .center ._nav-link.open .dropdown-options{
display: flex;
}

.dashboard .left-menu .center ._nav-link .main-link{
display: flex;
align-items: center;
}



.dashboard .left-menu .center ._nav-link svg {
margin-right: 0.8rem;
opacity: .5;
flex-shrink:0;
}



.dashboard .left-menu .center  ._nav-link .main-link:hover,
.dashboard .left-menu .center  ._nav-link.active .main-link
{
background: #2360E4;
transition: 0.1s ease;
cursor: pointer;
}


.dashboard .left-menu .center  ._nav-link  span{

font-size: 1rem;
min-width: 90px;

}

.dashboard .left-menu .center  ._nav-link .dropdown-options span{
font-size:0.95rem;
margin-bottom: 0.7rem;
cursor: pointer;
max-width: 130px;
opacity: .7;
position: relative;
}
.dashboard .left-menu .center  ._nav-link .dropdown-options span:after{
 position: absolute;
 content: "";
 width: 3px;
 height: 3px;
 border-radius: 30%;
 background: #111;
 top: 50%;
 transform: translateY(-50%);
 left: -1.2rem;
 border:2px solid #111;
 opacity: .5;

}
.dashboard .left-menu .center  ._nav-link .dropdown-options span.active:after{
background: #1757DB;
border:2px solid #1757DB;
}

.dashboard .left-menu .center  ._nav-link .dropdown-options span.active
{
color: #054ee5;
}
.dashboard .left-menu .center  ._nav-link .dropdown-options span:hover{
opacity: 1;
}
.dashboard .left-menu .center  ._nav-link label{
margin-left: 0.2rem;
display: flex;
align-items: center;
transform-origin: center;
transform: translateX(0.4rem);
}
.dashboard .left-menu .center  ._nav-link.active label,
.dashboard .left-menu .center  ._nav-link.open label
{
 transform: rotate(90deg)  translateX(0.5rem);
}


.dashboard .left-menu .center  ._nav-link  .main-link:hover  span,
.dashboard .left-menu .center  ._nav-link.active .main-link  span
{
color: #fff;
opacity: 1;
}

.dashboard .left-menu .center  ._nav-link .main-link:hover svg,
.dashboard .left-menu .center  ._nav-link.active svg
{
fill: #fff !important; 
opacity: 1;
cursor: pointer;
}

.dashboard .left-menu .bottom{
flex: 1 ;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
}

/**** dasboard center **********/
.dashboard .main-dashboard .center_search {
display: flex;
margin: 2rem 0;
justify-content: space-between;
}

.dashboard .main-dashboard .center_search .search_container .search-div{
position: relative;
height: 40px;
}

.dashboard .main-dashboard .center_search  .btn-add-item{
background: #1757DB;
display: flex;
align-items: center;
justify-content: center;
padding: 0.4rem 1rem;
color: #fff;
border-radius: 0.3rem;
cursor: pointer;
font-size: 0.9rem;
}
.dashboard .main-dashboard .center_search  .btn-add-item:hover{
opacity: .8;
}



.dasboard-item-list-container{
width: 100%;
background: #fff;
padding: 1rem;
border-radius: 0.3rem;

}

.dasboard-item-list-container .top-section{
 width: 100%;
 display: flex;
 justify-content: space-between;
 margin-bottom: 0.8rem;
}
.dasboard-item-list-container .top-section .left-side{
display: flex;
flex-wrap: wrap;
width: 100%;
}
.dasboard-item-list-container .top-section .left-side .show{
border: 1px solid #ebebeb;
padding: 0.4rem 0.6rem;
border-radius: 0.2rem;
font-weight: 400;
margin-right: 1.4rem;
background: #f3f3f399;
margin-bottom: 0.4rem;
}

.dasboard-item-list-container .top-section .left-side .show span{
margin-right: 0.2rem;
opacity: .7
}
.dasboard-item-list-container .top-section .left-side .show label{
font-weight: 400;
padding: 0.2rem 0.1rem;
}

.dasboard-item-list-container .top-section .left-side select{
border: 1px solid #ebebeb;
outline: none;
background: #fff;

}

.dasboard-item-list-container .top-section .right-side {
display: flex;
}

.dasboard-item-list-container .top-section .right-side .view{
display: flex;
border: 1px solid #ebebeb;
border-radius: 0.2rem;

}
.dasboard-item-list-container .top-section .right-side .view span{
display: flex;
padding: 0.4rem 0.6rem;
opacity: .6;
}
.dasboard-item-list-container .top-section .right-side .view span.active{
opacity: 1;
}
.dasboard-item-list-container .top-section .right-side .view span:first-child{
border-right:  1px solid #ebebeb; 
}

.dasboard-item-list-container .top-section .right-side .page{
display: flex;
border: 1px solid #ebebeb;
border-radius: 0.2rem;
justify-content: center;
align-items: center;
width: 32px;
margin-left: 1rem;
height: 32px;
}
.dasboard-item-list-container .top-section .right-side .page:not(.export){

display: none;
}

.dasboard-item-list-container .top-section .right-side .export{
cursor: pointer;
}

.dasboard-item-list-container .top-section .right-side .export:hover{
opacity: .7;
}

.dasboard-item-list-container .top-section .right-side .extract-btn{
  position: relative;
}
.dasboard-item-list-container .top-section .right-side  .extract-btn .btn{
  display: flex;
  cursor: pointer;
}

.dasboard-item-list-container .top-section .right-side  .extract-btn .btn:hover{
  opacity: .8;
}

.dasboard-item-list-container .top-section .right-side  .extract-btn  .page{
  width: 35px;
  margin-right: 0.4rem;
  height: 35px;
}
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog{
 position: absolute;
 right: 0;
 box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
 border-radius: 0.3rem;
 background-color: #fff;
 z-index: 1;
 width: 230px;
 transform: translateY(50px);
 opacity: 0;
 pointer-events: none;
}

.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog.active{
  transform: translateY(0);
  opacity: 1;
  pointer-events: visible;
 }
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog .input-container{
 padding: 1rem;
}
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog .input-container .inputs{
  margin-bottom: 0.5rem;
}
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog .input-container span{
  font-size: 0.9rem;
}
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog .options{
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  opacity: .3 !important;
  pointer-events: none;
}
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog .options.active{
  opacity: 1 !important;
  pointer-events: visible;
}
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog .options span{
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #8080804f;
  width: 100%;
  cursor: pointer;
}
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog .options span:hover{
  color: var(--main-color);
}
.dasboard-item-list-container .top-section .right-side  .extract-btn  .dialog .options span:last-child{
  border-bottom: 0 !important;
}

.dasboard-item-list-container .top-section .right-side  .extract-btn  .page svg{
  
}

.delete-dialog{
position: absolute;
top: 4rem;
transform: translateX(-50%);
left: 50%;
z-index: 1;
background: #fff;
border-radius: 0.5rem;
padding: 1rem;
width: 390px;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
transition: 0.3s;
}

.dialog-container-bg.show .delete-dialog{
animation: dialogs 0.4s forwards;
}

.dialog-container-bg{
width: 100%;
height: 100vh;
position: absolute;
top: 0;
left: 0;
background: rgba(0,0,0,.4);
z-index: 2;
display: none;
overflow: auto;
margin-bottom: 4rem;
}
.dialog-container-bg.show{
display: block;
z-index: 1000;
}
.dialog-container-bg .bg-close
{
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;

}
.create-microcredit{
position: absolute;
top: 4rem;
transform: translateX(-50%) translateY(2rem);
left: 50%;
z-index: 1;
background: #fff;
border-radius: 0.9rem;
padding: 1rem;
width: 520px;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
transition: 0.3s;
opacity: 0;
overflow: hidden;
}

.create-microcredit .media-items{
width: 100%;
border-radius: 0.3rem;
background-color: var(--border-color);
padding: 0.2rem;
display:flex;
flex-wrap: wrap;
max-height: 80px;
overflow: auto;
}
.media-items .item{
border-radius: 0.3rem;
background-color: #cbcbcb;
width:30px;
height: 30px;
margin-right: 0.2rem;
display: flex;
justify-content: center;
align-items: center;
position: relative;
margin-bottom: 0.2rem;
position: relative;
}
.create-microcredit .media-items .item:after{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  content: "";
  background: rgba(0, 0, 0, 0.381);
  opacity: 0;
  border-radius: 0.3rem;
}
.create-microcredit .media-items .item .file-icon{
display: flex;
z-index: -2 !important;
position: relative;
}

.create-microcredit .media-items .item:hover .file-icon{
display: none;
}
.create-microcredit .media-items .item:not(.add-file):hover:after{
opacity: 1;
}
.media-items .item *{
z-index: 2;
}

.create-microcredit .media-items .item{
width: 40px;
height: 40px;
}

.create-microcredit .media-items .item .loader
{
  width: 20px;
  height: 20px;
  border: 2px solid #6a6969;
  border-top:  2px solid transparent;
  border-left:  2px solid transparent;
  border-radius: 50%;
  animation: media_loader 0.6s linear infinite;
  display: none;
  pointer-events: none;
}

.settings-loader{
  width: 20px;
  height: 20px;
  border: 2px solid #2360E4;
  border-top:  2px solid transparent;
  border-left:  2px solid transparent;
  border-radius: 50%;
  animation: media_loader 0.6s linear infinite;
}


.create-microcredit .media-items.loading .item .loader{
display: block;
}

.create-microcredit .media-items.loading .item .add-icon{
display: none;
}



@keyframes media_loader{
from{
      transform:rotate(-360deg);
}to{
      transform: rotate(0deg);
}
}
.create-microcredit .media-items .item.add-file{
background-color: transparent;
}
.create-microcredit .media-items .item.add-file:hover{
opacity: .6;
}
.create-microcredit .media-items .item:hover{
cursor: pointer;
}

.create-microcredit .media-items .item .close-icon{
position: absolute;
width: 15px;
height: 15px;
border-radius: 50%;
top: -5px;
display: flex;
justify-content: center;
align-items: center;
right:-5px;
border:1px solid #11111119;
background-color: #fff;

}
.create-microcredit .media-items {
border:2px dotted rgba(0, 0, 0, 0.105);
}
.create-microcredit .media-items .item .see-icon{
display: none;
}
.create-microcredit .media-items .item:hover .see-icon,
.create-microcredit .media-items .item:hover .close-icon
{
display: block;
}

.create-microcredit .media-items .item.add-file svg,
.create-microcredit .media-items .item.file-icon svg
{
fill: #161A1D;
opacity: .4;
}
.create-microcredit .media-items .item .see-icon svg{
fill: #fff;
opacity: .9;
}

.create-microcredit .box-content{
width: 80%;
margin: 2rem auto;
border: 2px dashed #cececec7;
padding: 20px;
cursor: pointer; 
border-radius: 0.3rem; 
background-color: #fefefe;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

}
.create-microcredit.import-excel{
width:460px;
}

.remove-payment{
  color:crimson !important;
}
.remove-payment:hover{
  opacity: 1 !important;
}
.create-microcredit .box-content span{
opacity: .6;
}

.create-microcredit .box-content button{
padding: 0.4rem 0.6rem;
border-radius: 0.3rem;
color: #fff;
background: var(--main-color);
margin-top: 1rem;
pointer-events: none;
}

.create-microcredit .box-content input{
position: absolute;
opacity: 0;
pointer-events: none;
}

.create-microcredit.import-excel .note{
width: 100%;
margin-top: 2rem;
opacity: .7;
padding: 0 20px;
}
.excel-details{
width: 100%;
margin: 1.5rem 1rem;
display: flex;
justify-content: space-between;
align-items: center;
}
.create-microcredit.import-excel .excel-details ._title{
opacity: .7;
}
.create-microcredit.import-excel .excel-details button{
padding: 0.2rem 0.2rem;
border-radius: 0.3rem;
background: #f4f5f7;
color: #8a8a8a !important;
margin-right: 1rem;
border: 0;
}
.create-microcredit.import-excel .excel-details button:hover{
opacity: .6;
}

.create-microcredit.import-excel  .table-container{
min-height: 200px;
font-size: 0.8rem;
}
.create-microcredit  .table-container td{
opacity: .6 !important;
}
.create-microcredit  .table-container td:hover{
background-color: transparent;
}
.create-microcredit.import-excel .note a,
.create-microcredit.import-excel .note label
{
color: var(--main-color);
cursor: pointer;
}


.create-microcredit .details{
width: 100%;
position: absolute;
top: 0;
left: 0;
background: #fff;
height: 100%;
z-index: 1;
border-radius: inherit;
overflow: auto;
padding: 1rem;
opacity: 0;
pointer-events: none;
transform: translateX(100%);

}
.create-microcredit .details.show{
opacity: 1;
pointer-events: visible;
transform: translateX(0%);
transition: 0.3s ease;
}

.create-microcredit .details  input[disabled]{
opacity: .6;
}

.dialog-container-bg.show .create-microcredit{
animation: dialogs 0.4s forwards;
}

@keyframes  dialogs {
0% {
 transform: translateX(-50%) translateY(2rem);
 opacity: 0;
}

100% {
  transform: translateX(-50%) translateY(0);
  opacity: 1;
}
}

.create-microcredit.select-c{
width: 320px;
}

.create-microcredit.select-c .c-options{
 display: flex;
 flex-direction: column;
 margin-top: 1rem;
}


.create-microcredit.select-c .c-options .o{
padding: 0.5rem 0.3rem;
display: flex;
flex-direction: column;
border-bottom: 1px solid #ebebeb;
cursor: pointer;
border-radius: 0.3rem;
min-height: 50px;
position: relative;

}
.create-microcredit.select-c .c-options .o .micro{
font-size: 1.2rem;
}
.create-microcredit.select-c .c-options .o:hover{
background: #ebebeb;
}

.create-microcredit.select-c .c-options .o:last-child{
border-bottom: 0;
}
.create-microcredit.select-c .c-options .o .name{
font-size: 0.9rem;
margin-bottom: 0.5rem;
opacity: .7;
}
.create-microcredit.select-c .c-options .o .role{
font-size: 0.9rem;
opacity: .7;
position: absolute;
right: 0.4rem;
top: 0.5rem;
}
.delete-dialog p{
padding: 1.5rem 1.3rem;
font-size: 1.2rem;
text-align: center;
}



.create-microcredit .top-title
{
padding: 0.4rem;
border-bottom: 1px solid #ebebeb;
font-weight: 500;
opacity: .8;
}
.create-microcredit .details .top-title{
padding:0 0.4rem;
border-bottom: 1px solid #ebebeb;
font-weight: 500;
opacity: .8;
padding-left: 2rem;
padding-bottom: 0.8rem;
}
.delete-dialog .alert-icon{
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

}

.create-microcredit .status{
position: absolute;
top: 0.5rem;
right: 4rem;
cursor: pointer;
border-radius: 3rem;
background: #ebebeb;
padding: 0.5rem 0.7rem;
font-size: 0.8rem;
}

.loading-editing-item .status,
.loading-editing-item .top-title,
.loading-editing-item .input-container,
.loading-editing-item .options
{
  display: none !important;
}



.create-microcredit .btn-close,
.delete-dialog .btn-close
{
position: absolute;
top: 1rem;
right: 1rem;
opacity: .4;
cursor: pointer;
}
.loading-editing-item .btn-close{
  display:  none !important;
}
.create-microcredit .details .btn-close {
  right: initial;
  left: 1rem;
}
.create-microcredit .btn-close:hover,
.delete-dialog .btn-close:hover
{
opacity: .4;
}
.create-microcredit .input-container{
margin-top: 2rem;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
min-height: 130px;

}
.extra-filters{
background: #fff;
border:1px solid var(--border-color);
border-radius: 0.3rem;
padding: 1rem;
margin-bottom: 1rem;
display: flex;
justify-content: space-between;

position: relative;
}
.extra-filters .options span{
display: flex;
border: 1px solid #ebebeb;
border-radius: 0.2rem;
justify-content: center;
align-items: center;
width: 32px;
margin-left: 1rem;
height: 32px;
background:#1757dbad;
cursor: pointer;
}
.extra-filters .input-container span,
.extract-btn .inputs span
{
font-size: 0.95rem;
margin-bottom: 0.2rem;
opacity: 0.9;
display: flex;
font-weight: 400;

}
.extra-filters .input-container{
display: flex;
flex-wrap: wrap;
position: relative;
}
.extra-filters .input-container .inputs{
 width: 200px;
 margin-right: 2rem;
 margin-top: 0.6rem;
}
.extra-filters .input-container .inputs select,
.extra-filters .input-container .inputs input,
.extract-btn .inputs select,
.extract-btn .inputs input
{
color: #293240;
font-weight: 400;
}
.create-microcredit .res{
 display: flex;
 margin-bottom: 0.2rem;
 height: 30px;
 align-items: center;
}
.create-microcredit .res svg{
margin-right: 0.2rem;
}
.input-container .inputs {
position: relative;
}
.input-container .inputs .btn-add{
position: absolute;
right: 0;
top: 0.2rem;
/*background:#F3F3F3;*/
padding: 0.05rem 0.1rem;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}

.__btn-add{
  cursor: pointer;
}

.input-container .inputs .btn-add svg,
.__btn-add svg
{
opacity: .3;
}

.input-container .inputs .btn-add:hover svg,
 .__btn-add:hover svg
{
opacity: .5;
}

.create-microcredit .input-container .inputs{
  display: flex;
  flex-direction: column;
  width: 48.5%;
  margin-bottom: 1.5rem;
  position: relative;
}
.create-microcredit .input-container .inputs.full{
 width: 100%;
}

.loan-container .main-section .input-container .inputs._full{
width: 98%;
}

@media screen and (min-width:800px){
.create-microcredit.client{
  width: 700px;
}
.create-microcredit.client .input-container .inputs{
  width: 32%;
}
} 


.create-microcredit .input-container .inputs .set-empty{
position: absolute;
right: 0;
top: 0.2rem;
opacity: 0;
cursor: pointer;
}

.create-microcredit .input-container .inputs:hover .set-empty{
opacity: .3;
}

.create-microcredit .input-container .inputs.not-null:hover .set-empty,
.create-microcredit .input-container .inputs.not-null .set-empty
{
opacity: 1;
}


.create-microcredit .input-container .inputs span{
opacity: .8;
font-size: 0.9rem;
margin-bottom: 0.4rem;
}
.create-microcredit .input-container .inputs span label{
color: crimson;
}
.create-microcredit .input-container .inputs div.label{
display: flex;
justify-content: space-between;
}
.create-microcredit .input-container .inputs div.label label{
display: flex;
align-items: center;
opacity: .7;
font-size: 0.9rem;
cursor: pointer;
}
.create-microcredit .input-container .inputs div.label label input{
margin-left: 0.4rem;
border: 0;
cursor: pointer;
}
.inputs select{
font-size: 0.95rem;
}
.create-microcredit .input-container .inputs input,
.create-microcredit .input-container .inputs select,
.create-microcredit .input-container .inputs textarea,
.extra-filters .input-container .inputs input,
.extra-filters .input-container .inputs select,
.extra-filters .input-container .inputs textarea,
.extract-btn .inputs select,
.extract-btn .inputs input,
.loan-container .main-section .input-container .inputs select,
.loan-container .main-section .input-container .inputs input,
.loan-container .main-section .input-container .inputs textarea
{
border: 1px solid #D9D9D9;
border-radius: 0.2rem;
padding:0.4rem 0.3rem;
width: 100%;
/*background: #FCFCFC;*/
height: 38px;
 -webkit-transition: all 0.1s ease-in-out;
-moz-transition: all 0.1s ease-in-out;
-ms-transition: all 0.1s ease-in-out;
-o-transition: all 0.1s ease-in-out;
outline: none;
}


.create-microcredit .input-container input[type="checkbox"]{
width: 15px;
height: 15px;
}

.create-microcredit .input-container .inputs:not(.search-select) *:focus, .loan-container .main-section .input-container .inputs:not(.search-select) *:focus{
box-shadow: 0 0 4px #2360e48a;
border: 1px solid #2360e4a6;
}

.create-microcredit .input-container input::placeholder{
font-size: 0.8rem;
opacity: .8;
}

.create-microcredit  .options
{
border-top:  1px solid #ebebeb;
display: flex;
justify-content:  flex-end;
padding-top: 1rem;
}
.delete-dialog .options
{
  border-top: 1px solid #ebebeb;
  padding-top: 0.6rem;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}
.delete-dialog .options{
  border-top:  0;
}

.create-microcredit .options button,
.delete-dialog .options button
{
padding: 0.5rem 1rem;
border-radius: 0.3rem;
border: 0;
font-size: 1rem;
height: 40px;
}
.delete-dialog .options button{
margin:  0 1.6rem;
padding: 0.5rem 2rem;
}

.create-microcredit .options button.cancel,
.delete-dialog .options button.cancel
{
  background:#f8f9fa;
  color: #8a8a8a;
}


.delete-dialog .options button.save,
.create-microcredit .options button.save
{
 background: #2360E4;
 color: #fff;
 margin-left: 0.6rem;
}
.options button.save:hover,
.options button.cancel:hover
{
opacity: .9;
}
.div_btn{
 position: relative;
}

.div_btn .loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 25px;
  height: 25px;
  border: 2px solid #ddd;
  border-top:  2px solid transparent;
  border-left:  2px solid transparent;
  border-radius: 50%;
  float: right;
  animation: btn_loader 0.6s linear infinite;
  opacity: 0;
  pointer-events: none;

}

.create-microcredit.loading  .div_btn .loader{
 opacity: 1;
 pointer-events:visible;
}
.create-microcredit.loading  button.cancel,
{
 opacity: 0 !important;
 pointer-events: none;
}

.create-microcredit.loading span{
 opacity: 0;
 pointer-events: none;
}

@keyframes btn_loader{
from{
       transform:translate(-50%,-50%) rotate(-360deg);
}to{
       transform:translate(-50%,-50%) rotate(0deg);
}
}





.row-menu-dropdown{
display: flex;
flex-direction: row;
}


.main-dashboard .center .settings{
margin-top: 1rem;
}
.main-dashboard .center .settings-options{
width: 100%;
border-radius: 0.3rem;  
background-color: #fff;
padding: 0 1rem;
border:1px solid var(--border-color);
margin-bottom: 3rem;
}

.set.option-c{
width: 100%;
position: relative;

}
.set.option-c .top-title{
position: absolute;
display: table;
padding: 0.1rem 1rem;
background: #fff;
transform: translateY(-80%);
border-radius: 0.3rem;
border:1px solid var(--border-color);
font-size: 0.9rem;
color: #0000009e;
font-weight: 600;
}

.set.option-c .option{
width: 100%;
display: flex;
border-bottom:1px solid var(--gray-color);
padding: 0.6rem 0;
justify-content: space-between;
align-items: center;
border-bottom:1px solid var(--border-color);
}



.set.option-c .option:last-child
{
  border-bottom:1px solid transparent;
}
.set.option-c .option:first-child{
  border-bottom:1px solid var(--border-color);
  padding-top: 2rem !important;
}

.set.option-c .option .title{
  opacity: .7;
  color: var(--black-color);
  font-size: 0.9rem;
  font-weight: 400;
}


.set.option-c .option select{
  color: #333;
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  font-size: 15px;
  border:1px solid var(--gray-color);
  padding: 0.4rem;
  border-radius: 0.3rem;
  background-color:rgb( 241,243,244 );
  color: #111111ab;
  outline:none;
}
.set.option-c .option .switch,
.main-dashboard .content ._table .item .switch,
.container .pop-ups .c ._automate .switch
{
 width: 42px;
 height: 24px;
 border-radius: 20px;
 border:1px solid transparent;
 position: relative;
 cursor: pointer;
 transition: background-color linear 80ms;
 transform: scale(1);
 background-color:rgb(241 243 244 / 28%);
 transition: 0.1s ease-out;
 box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.05);
 display: flex;
 justify-content: space-around;
 align-items: center;
 padding: 0 2px;
}

.container.dark-theme .set.option-c .option .switch{
  background: rgb(241 243 244 / 9%);
}

.set.option-c .option .switch.active,
.main-dashboard .content ._table .item .switch.active,
.container.dark-theme  .set.option-c .option .switch.active,
.container .pop-ups .c ._automate .switch.active
{
background-color: #2360E4;
}



.set.option-c .option .switch::before,
.main-dashboard .content ._table .item .switch::before,
.container .pop-ups .c ._automate .switch::before
{
  width: 18px;
  height: 18px;
  background-color:#fff;
  border-radius: 20px;
  position: absolute;
  content: "";
  left: 0;
  top: 1px;
  transition: 0.1s ease-out;
  transform: translateY(1px) translateX(2px);
  border: 1px solid rgba(207, 205, 205, 0.863);

}
.container.dark-theme .set.option-c .option .switch::before,
.container.dark-theme .main-dashboard .content ._table .item .switch::before,
.container.dark-theme .pop-ups .c ._automate .switch::before
{
background-color:rgba(255, 255, 255, 0.541); 
}

.set.option-c .option .switch.active::before,
.main-dashboard .content ._table .item .switch.active::before,
.container .pop-ups .c ._automate .switch.active::before
{
  transform: translateY(1px) translateX(18px);
}
.set.option-c .option .switch svg{
z-index: 2;
}
.set.option-c .option .switch svg.light{
fill: var(--main-color);
}
.set.option-c .option .switch svg.dark{
fill:rgba(0,0,0,0.7);
}

.set.option-c .option .switch.active svg.light{
fill:#fff;
}
.set option-c .option .switch.active  svg.dark{
 fill: var(--main-color);
}


.set.option-c .option div{
  position: relative;
}




.set.option-c .option div.loading::after{
  content: "";
  position: absolute;  
  width: 24px;
  height: 24px;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 2px solid #ddd;
  border-top:  3px solid var(--main-color);
  border-left:  3px solid var(--main-color);
  border-radius: 50%;
  animation: _setting_option_loader 1s linear infinite;
  z-index: 3;
  background: var(--box-color);
}


.settings.loading .set.option-c .option div:not(.loading) .switch{
  opacity: .5;
  pointer-events: none;
}

.set.option-c .option div.loading .switch,
.settings.loading .option div.loading .switch
{
  opacity: 0 !important;
  pointer-events: none;
}
@keyframes _setting_option_loader{
  from{
       transform:translate(-50%,-50%) rotate(-360deg);
  }to{
      transform: translate(-50%,-50%) rotate(0deg);
  }
}








.loan-container .MuiTablePagination-root,
.loan-container  .MuiDataGrid-selectedRowCount,
.loan-container  .MuiDataGrid-footerContainer
{
/*display: none;*/
}


.create-microcredit .payment-top-cards
{
 display: flex;
 flex-wrap: wrap;
 margin-top: 1rem;
}



.create-microcredit .payment-top-cards .item,
.create-microcredit  .payment-res
{
 background: #F3F3F3;
 border-radius: 0.2rem;
 display: table;
 padding: 0.5rem 1rem;
 margin-right: 0.5rem;
}
.create-microcredit .payment-top-cards .item{
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: 0.4rem;
}
.create-microcredit .payment-top-cards .item span{
margin: 0 0.4rem;
font-size: 0.8rem;
}
.create-microcredit .payment-top-cards .item span.value,
.create-microcredit  .payment-res span.value
{
font-weight: 600;
}
.create-microcredit  .payment-res{
margin-bottom: 3rem;

}
.create-microcredit  .payment-res span.value{
 margin-left: 0.6rem;
}
.create-microcredit  .payment-res span.name{
color:#7398E5;
}


.dashboard-container .skeleton{
  position: relative;
  overflow: hidden;
}

.dashboard-container * .skeleton-container{
  display:none;
}
.dashboard-container *.skeleton .skeleton-container{
  display: flex;
}

.dashboard-container .skeleton-bg{
  position: relative;
}

.dashboard-container.loading .skeleton-bg::after{
  content: "";
  width: 100%;
  height: 100%;
  background:linear-gradient(90deg, #ffffff 25%, #f0f0f0 50%, #ffffff 75%);
  background-size: 200% 100%;
  animation: skeletonAnimation 3s infinite;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: inherit;
}

.dashboard-container.loading.loading-only-filtered .skeleton-bg::after{
 display: none;
}
.dashboard-container.loading.loading-only-filtered .skeleton-bg.load-on-filtering::after{
 display: block;
 }

.admin-company-table table{
  border-radius: 0.4rem;
  min-height: 400px;
}

.admin-company-table table th{
  font-weight: 600;
}

.dashboard-container .skeleton-container{
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
z-index: 1;
}
.table_actions.hide{
opacity: .4;
pointer-events: none;
cursor: initial;
}
.table_actions{
display: flex;
}
.table_actions span{
margin:0.5rem;
border-radius: 0.3rem;
background: #eee;
display: table;
padding: 0.25rem;
}
.table_actions svg{

}

/*@page { size: A4; margin: 0; }*/

.quill-ed{
display: none;
}
.ql-editor{
background: #fff;
}

.tables .c-table{
margin-top: 2rem;
background-color: #fff;
border-radius: 0.4rem;
}

.tables .table-top{
display: flex;
padding: 1rem;
justify-content: space-between;
}


@keyframes skeletonAnimation {
    0% {
      background-position: -200px 0;
      opacity: 1;
    }
    
    100% {
      background-position: 200px 0;
    }

}

@media (max-width: 1024px){

.dashboard .main-dashboard .top .search_container *{
   display: none;
}


}



@media (max-width: 768px){


  .hide_in_max_md{
    display: none !important;
  }


  .dashboard .main-dashboard .center .stat_page_1 .left-side .items .box .total{
    font-size: 0.92rem !important;
  }

  .extra-filters  .options{
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;

  }

  .extra-filters{
    padding-top: 2.8rem;
  }
  .extra-filters._hide .input-container{
    display: none;
  }

.dashboard .main-dashboard .center_search .btn-add-item {
   margin-left: 1rem;
   border-radius:2rem;
}

.dashboard  .left-menu{
 transform: translateX(-100%);
 transition: 0.2s ease-out;
 position: absolute;
 left: 0;
 top: 0;
 z-index: 3;
}

 .dashboard .left-menu .center ._nav-link .dropdown-options span:after{
    transform: translateX(-0.4rem);
 }

 .dashboard .left-menu.open .btn-close-menu{
   display: flex;
}
.dashboard .left-menu.open .btn-close-menu svg{
  opacity: .6;
}


.dashboard .left-menu ._c{
    transform: translateX(-21px);
}
.dashboard .left-menu ._c h1{
    opacity: 0;
}


.dashboard  .left-menu.open{
       width: 100%;
       transform: translateX(0%);
}

.dashboard  .left-menu.open ._c{ 
      transform: translateX(0px);
      width: 100%;
}
.dashboard  .left-menu.open ._c .center{
  padding-bottom: 70px;
}
.dashboard .left-menu.open ._c h1{
    opacity: 1;
}

.btn_open_close_menu{
  display: flex;
}
.btn_open_close_menu.close{
  display:none;
}

.dashboard .main-dashboard .stat_page_2 .box .center-content div.name{
  margin-bottom: 0.2rem;
}
.dashboard .main-dashboard .stat_page_2 .box .center-content div.total{
  font-size: 0.9rem;
}


}

.inputs .search-select .css-1fdsijx-ValueContainer{
padding: 0 !important;
}
.inputs .search-select .css-qbdosj-Input {
   margin: 0 !important;
   padding-bottom:0 !important;
   padding-top: 0 !important;
}



@media (max-width: 768px){
 .dialog-container-bg{
   z-index: 99;
 }
 .create-microcredit{
    width: calc(100% - 2rem);
    border-radius:0.3rem;
    top: 2rem;
    /*
    min-height: calc(100vh - 4rem);*/
 }
 .create-microcredit .options{
    border-top:0;
 }

.dashboard .main-dashboard .top .current_tab .path
{
  display: none;
}
  
}


@media screen and (max-width:600px){

  .dasboard-item-list-container .top-section .left-side .show{
    width: 48%;
    margin-right: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .extra-filters .input-container{
    width: 100%;
  }
 
  .dashboard .main-dashboard .top .right-options .top-tabs{
     display: none;
  }

  .extra-filters .input-container .inputs{
    margin-right: 0;
  }

  .center_search .search_container{
    width: 100%;
  }

  .center_search  .flex{
    width: 100%;
    justify-content: end;
    margin-top: 0.7rem;
  }
  
  .dashboard .main-dashboard .top .right-options .user .info/*,
   .dashboard .main-dashboard .center_search .btn-add-item span*/
  {
    display: none;
  }
 
  .extra-filters .input-container .inputs{
    width: 100%;
  }

  
  .dasboard-item-list-container{
    position: relative;
    padding-top: 2.8rem;
  }

  .dashboard .main-dashboard .center_search .filter{
    margin-left: 0.4rem;
  }
  .btn-filter span{
    display: none;
  }
  .dashboard .main-dashboard .center_search .filter .btn-filter svg{
    margin-right: 0;
  }

  .dasboard-item-list-container .right-side{
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
  }

  .dashboard .main-dashboard .center_search{
    flex-direction: column;
    margin-bottom: 0.9rem;
  }

  .dashboard .main-dashboard .center_search {
    align-items: flex-start;
  }
  .dashboard .main-dashboard .btn-add-item{
    margin-left:0 !important;
  }

  .loan-container .top-options span{
    font-size: 0.8rem !important;
  }
  .loan-container .top-options span label{
    display: none;
  }

  .loan-container .main-section .count-cards .item label{
    font-size: 0.8rem;
  }

  .loan-container .message{
    left: 4rem !important;
    width: 70% !important;
  }

   
}


@media (max-width: 500px){
 .create-microcredit .input-container .inputs {
    width: 100%;
  }

  .dashboard .main-dashboard .top .right-options .top-tabs{
   margin-left: 0.5rem;
  }

  .delete-dialog{
    transform: translateY(-50%);
    left: 2rem;
    border-radius: 0.5rem;
    top: 50%;
    width: calc(100% - 4rem);
  }
}


.table-loader,.create-loan-requirements-loader .loader{
  width: 20px;
  height: 20px;
  border: 2px solid #979494;
  border-top:  2px solid #ddd;
  border-left:  2px solid #ddd;
  border-radius: 50%;
  animation: table_loader 0.8s linear infinite;
  pointer-events: none;
  display: table;
  margin-right: 0.4rem;
} 

.create-loan-requirements-loader{
display: flex;
justify-content: center;
margin: 2rem;
}

.create-loan-requirements-loader .loader{
margin-right:0;
width: 26px;
height: 26px;

}

@keyframes table_loader{
from{
       transform:rotate(-360deg);
}to{
       transform:rotate(0deg);
}
}

oader:not(.p) svg{
  display: none !important;
}

[print]{
 display: none;
 position: absolute !important;
}

/*
@media print {
  
  @page {
    size: A4; 
    margin: 5mm; 
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: visible;
  }

  body * {
    visibility: hidden;
  }

  ._print_div {
    display: block !important;
    visibility: visible !important;
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    overflow: visible !important;
  }

  ._print_div * {
    visibility: visible !important;
  }

  table {
    width: 100%;
    page-break-inside: auto;
    border-collapse: collapse;
  }

  tr, td, th {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  div[print] > div {
    page-break-before: auto;
    page-break-after: avoid;
  }

  div[print] > div:not(:last-child) {
    page-break-after: always; 
  }

  div[print]:last-child {
    page-break-after: auto;
  }

  div[print]:empty {
    display: none;
  }
  
}
  */




@media print {

    *::-webkit-scrollbar {
      display: none;
    }
 
    ._print_div {
      display: block !important;
      visibility: visible !important;
      width: 100% !important;
      height: 200px !important;
      overflow: visible !important;

    }

  body {
    padding: 0;
    margin: 0;
    background: #fff;
    width: 100%;
  }

  body * {
    visibility: hidden;
  }

  ._print_div, ._print_div * {
    visibility: visible;
  }



    
@page {
  size: letter;
  margin: .5in;
}

 ._footer {
  width: 100%;
  border:0 !important;
}

._header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px !important;
  border:0 !important;
}


table.paging,  table.paging thead td {
  height: 150px !important;
  border:0 !important;
}

 table.paging tfoot td {
  height: 150px !important;
  border:0 !important;
}


._header, ._footer {
  position: fixed;
}

._footer {
  bottom: 0;
}



  
._print_content table {
  width: 100%;
  border-collapse: collapse;
  page-break-inside: auto;
}

._print_content thead {
  display: table-header-group; 
}

._print_content tbody {
  display: table-row-group;
}

._print_content tr {
  page-break-inside: avoid;
}

._print_content table {
border-collapse: collapse;
width: 100%;
}

._print_content thead {
display: table-header-group;
}

._print_content tbody {
display: table-row-group;
}

._print_content tfoot {
display: table-footer-group; 
}

.signatures {

  margin-top: auto; 
  page-break-inside: avoid; 
  text-align: center;
  padding-top: 10px;

}
 
}










 

